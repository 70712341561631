import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Loading from 'src/reusable/Loading';
import 'spinkit/spinkit.min.css';
import { Token } from './views/pages/token/Token';
import './scss/style.scss';

// Pages
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

const App = () => {
  return (
    <HashRouter>
      <React.Suspense fallback={<Loading />}>
        <Switch>
          <Route
            exact
            path="/token/:token"
            name="TokenPage"
            render={(props) => <Token {...props} />}
          />

          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />

          <Route
            exact
            path="/404"
            name="Page404"
            render={(props) => <Page404 {...props} />}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
