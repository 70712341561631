import {
  SET_CURRENT_USER,
  SET_USER_TEAMS,
  UPDATE_USER_SELECTED_TEAM,
  SET_PLAYERS,
  UPDATE_PLAYERS,
  CLEAR_USER,
  SET_USER_SELECTED_TEAM,
} from "./constants";

/**
 * Set current user
 */
export function setCurrentUser(payload) {
  return {
    type: SET_CURRENT_USER,
    payload,
  };
}

export function setUserTeams(payload) {
  return {
    type: SET_USER_TEAMS,
    payload,
  };
}

export function updateUserTeam(payload) {
  return {
    type: UPDATE_USER_SELECTED_TEAM,
    payload,
  };
}

export function setTeam(payload) {
  return {
    type: SET_USER_SELECTED_TEAM,
    payload,
  };
}

export function setPlayers(payload) {
  return {
    type: SET_PLAYERS,
    payload,
  };
}

export function updatePlayers(payload) {
  return {
    type: UPDATE_PLAYERS,
    payload,
  };
}

export function clearUser() {
  return {
    type: CLEAR_USER,
  };
}
