export * from './crypto-helper';
export * from './firebase';

export const navigateToLogin = () => {
  const url =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : 'https://gamer-metrics.web.app';
  return window.location.assign(`${url}/login`);
};
