import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

if (!firebase.apps.length) {
  // Initialize Firebase
  // firebase.initializeApp({
  //   apiKey: process.env.REACT_APP_API_KEY,
  //   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  //   projectId: process.env.REACT_APP_PROJECT_ID,
  //   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  //   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  //   appId: process.env.REACT_APP_APP_ID,
  //   measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  // });

  // Production.
  firebase.initializeApp({
    apiKey: 'AIzaSyD7Yr9mya2Y8Q_LxUqiaKCpXSt0hC1bdnM',
    authDomain: 'gamer-metrics.firebaseapp.com',
    projectId: 'gamer-metrics',
    storageBucket: 'gamer-metrics.appspot.com',
    messagingSenderId: '686331050899',
    appId: '1:686331050899:web:c89a1d038bc9053b540666',
    measurementId: 'G-QV0TPKRC10',
  });
}

const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
const storage = firebase.storage();

// eslint-disable-next-line no-restricted-globals
// if (window.location.hostname === 'localhost') {
//   db.useEmulator('localhost', 8080);
//   functions.useEmulator('localhost', 5001);
//   auth.useEmulator('http://localhost:9099/', { disableWarnings: true });
//   storage.useEmulator('localhost', 9199);
// }

export { db, auth, storage, functions, firebase };
export default firebase;
