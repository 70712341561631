import produce from "immer";

import { SET_THEME } from "./constants";

const INITIAL_STATE = {
  sidebarShow: "responsive",
  asideShow: false,
  darkMode: false,
};

const themeReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_THEME:
        return {
          ...state,
          ...action.payload,
        };

      default:
        return state;
    }
  });

export default themeReducer;
