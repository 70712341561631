import React from "react";

const Loading = () => (
  <div className="d-flex justify-content-center">
    <div className="sk-bounce">
      <div className="sk-bounce-dot"></div>
      <div className="sk-bounce-dot"></div>
    </div>
  </div>
);

export default Loading;
