import produce from 'immer';

import {
  SET_CURRENT_USER,
  SET_USER_TEAMS,
  UPDATE_USER_SELECTED_TEAM,
  SET_PLAYERS,
  UPDATE_PLAYERS,
  SET_USER_SELECTED_TEAM,
  CLEAR_USER,
} from './constants';

const INITIAL_STATE = {
  data: null,
  isLoggedIn: false,
  isAdmin: false,
  isOwner: false,
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CURRENT_USER:
        return {
          ...state,
          ...action.payload,
        };

      case SET_USER_TEAMS:
        return {
          ...state,
          team: {
            ...action.payload,
          },
        };

      case SET_USER_SELECTED_TEAM:
        return {
          ...state,
          selectedteam: action.payload,
        };

      case UPDATE_USER_SELECTED_TEAM:
        return {
          ...state,
          selectedteam: action.payload,
        };

      case SET_PLAYERS:
        return {
          ...state,
          players: [...action.payload],
        };

      case UPDATE_PLAYERS:
        return {
          ...state,
          players: [...state.players, action.payload],
        };

      case CLEAR_USER:
        return {
          ...INITIAL_STATE,
        };

      default:
        return state;
    }
  });
export default reducer;
