import crypto from 'crypto-js';

export const encrypt = (text) => {
  const utfParse = crypto.AES.encrypt(
    JSON.stringify(text),
    process.env.REACT_APP_CRYPTO_SECRET_KEY,
  ).toString();
  return crypto.enc.Base64.stringify(crypto.enc.Utf8.parse(utfParse));
};

export const decrypt = (text) => {
  const base64 = crypto.enc.Base64.parse(text).toString(crypto.enc.Utf8);
  const jsonParse = crypto.AES.decrypt(
    base64,
    process.env.REACT_APP_CRYPTO_SECRET_KEY,
  ).toString(crypto.enc.Utf8);
  return JSON.parse(jsonParse);
};
