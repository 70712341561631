import React, { useEffect, useRef, useState, useCallback } from 'react';
import { setCurrentUser } from 'src/store/user/actions';
import { decrypt } from 'src/utils/crypto-helper';
import { auth, db } from 'src/utils/firebase';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Loading from 'src/reusable/Loading';
import { navigateToLogin } from 'src/utils';

export const Token = () => {
  const history = useHistory();
  const { token } = useParams();

  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const [loading, setLoading] = useState(true);

  const getUserInfo = useCallback(
    async (user) => {
      const usersRef = db.collection('users');
      const userResponse = await usersRef.doc(user.uid).get();
      const userData = userResponse.data();
      const route = userData.type.includes('superAdmin')
        ? '/organization'
        : '/exams';

      dispatch(
        setCurrentUser({
          isLoggedIn: true,
          isOwner: userData.type.includes('owner'),
          isAdmin: userData.type.includes('coach'),
          data: { id: user.uid, ...userData },
        }),
      );
      history.push(route);
      setLoading(false);
    },
    [dispatch, history],
  );

  useEffect(() => {
    isMounted.current = true;
    const checkingUser = async () => {
      if (token) {
        const user = decrypt(token);
        const currentUser = await auth.signInWithEmailAndPassword(
          user.email,
          user.password,
        );

        if (currentUser) {
          getUserInfo(currentUser.user);
          return;
        }

        navigateToLogin();
      } else {
        navigateToLogin();
      }
    };

    checkingUser();

    return () => {
      isMounted.current = false;
    };
  }, [getUserInfo, token]);

  if (loading) {
    return (
      <div className="screen-height d-flex align-items-center justify-content-center ">
        <Loading />
      </div>
    );
  }
};
